import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField  from '@material-ui/core/TextField';

import { get_company_data } from '../actions/db_data';

import { set_map_null } from '../actions/db_data';

import { update_quarter_value } from '../actions/db_data';

import { connect } from 'react-redux';

const QuarterDropdown = ({get_company_data,dropdown_list,set_map_null,company_data,first_time,update_quarter_value,quarter_value}) => {

const [default7, setDefault7] = useState('ok');




 const fetch_data = async (company_name) => {

 update_quarter_value(company_name);




    };



const default1= 'k';

const company_list=dropdown_list;

const quarter_list = ['Q1 2025','Q4 2024','Q3 2024','Q2 2024','Q1 2024','Q4 2023','Q3 2023','Q2 2023', 'Q1 2023','Q4 2022','Q3 2022','Q2 2022','Q1 2022','Q4 2021', 'Q3 2021', 'Q2 2021', 'Q1 2021', 'Q4 2020', 'Q3 2020', 'Q2 2020', 'Q1 2020', 'Q4 2019', 'Q3 2019', 'Q2 2019', 'Q1 2019', 'Q4 2018', 'Q3 2018', 'Q2 2018', 'Q1 2018', 'Q4 2017', 'Q3 2017', 'Q2 2017', 'Q1 2017', 'Q4 2016', 'Q3 2016', 'Q2 2016', 'Q1 2016', 'Q4 2015', 'Q3 2015', 'Q2 2015', 'Q1 2015', 'Q4 2014', 'Q3 2014', 'Q2 2014', 'Q1 2014', 'Q4 2013', 'Q3 2013', 'Q2 2013', 'Q1 2013', 'Q4 2012', 'Q3 2012', 'Q2 2012']





return (

<>

{
first_time==null   ?

null

:
     <Autocomplete

  id="combo-box-demo"
  options={quarter_list}

  value={quarter_value}

  getOptionLabel={(option) => option}
  style={{ width: 200 }}
  renderInput={(params) => <TextField {...params} label="Enter Quarter" variant="outlined" />}
  onChange= {(event,value)=> fetch_data(value) }

/>

}

</>
    );



};


 {/* onInputChange */}

const mapStateToProps = state => ({
    dropdown_list: state.db_data.dropdown_list,
    company_data: state.db_data.company_data,
    first_time: state.db_data.first_time,
    quarter_value: state.db_data.quarter_value


});

export default connect(mapStateToProps,{ get_company_data,set_map_null,update_quarter_value})(QuarterDropdown);