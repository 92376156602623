import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { pink } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';

import { update_check_value } from '../actions/db_data';

import { connect } from 'react-redux';

import { delete_selection_value } from '../actions/db_data';

import { intialise_list_value } from '../actions/db_data';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const RedCheckbox = withStyles({
  root: {
    color: pink[400],
    '&$checked': {
      color: pink[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const CheckType = ({update_check_value,delete_selection_value,intialise_list_value}) => {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedG: true,
  });

  const [state1, setState1] = React.useState([true,false]);

  const handleChange = (event) => {


    setState({ ...state, [event.target.name]: event.target.checked });
  };

    const handleChange1 = (event,type) => {

delete_selection_value();


if(type=='a'){

setState1([event.target.checked,!event.target.checked])

update_check_value(event.target.checked)

intialise_list_value('general')

}


else{

setState1([!event.target.checked,event.target.checked])

update_check_value(!event.target.checked)

intialise_list_value('bank')

}






  };

  return (
    <FormGroup row>

    <FormControlLabel
        control={<GreenCheckbox checked={state1[0]} onChange={(event)=>handleChange1(event,'a')} name="checkedG" />}
        label="General"
      />
 &nbsp; &nbsp;

 <FormControlLabel
        control={<RedCheckbox checked={state1[1]} onChange={(event)=>handleChange1(event,'b')} name="checkedA" />}
        label="Banking & Finance"
      />






    </FormGroup>
  );
}

export default connect(null,{ update_check_value,delete_selection_value,intialise_list_value})(CheckType);

