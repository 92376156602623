import React, { useState,useEffect } from 'react';
import HeatMap from 'react-heatmap-grid';
import { connect } from 'react-redux';
import { change_sentiment_box } from '../actions/db_data';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';

import Wait from "./Wait";

const CompanyMap = ({company_data,change_sentiment_box,selection_list,tab_view}) => {

const [row, setRow] = useState(0);
const [col, setCol] = useState(0);



const xLabels = new Array(24).fill(0).map((_, i) => `${i}`);

const yLabels = ['Sun', 'Mon', 'Tue'];
const yLabels1 = ['', '', '','','','',''];
const data = new Array(yLabels.length)
  .fill(0)
  .map(() => new Array(xLabels.length).fill(0).map(() => Math.floor(Math.random() * 100)));

 const updateState = (x,y) => {
    setRow(x);
    setCol(y);

    change_sentiment_box(company_data[3][y][x],company_data[1][x],y);


  };


useEffect(() => {

setRow(0);
setCol(0);


  }, [company_data]);


    return (
<>
     { ( company_data == null) ?
 <div style={{
        'minHeight':'250px',
        'margin': '50px 0',
       'margin-bottom': '20px',
      'padding': '30px 50px',
      'text-align': 'center',
    'border-radius': '4px',}}> <Wait /> </div>
 :
<div style={{ overflowX: "auto", height:"400px",display:"flex"}}>
{
tab_view=='specific' ?
     <HeatMap
    xLabels={company_data[1]}
    yLabels={yLabels1}
    data={company_data[0]}

    height={50}
    yLabelWidth={0}
    xLabelWidth={50}
    onClick={(col,row)=> updateState(col,row)}

    cellStyle={(background, value, min, max, data, x, y) => ({
          background:
            value === 0
              ? "#18A367"
              : value === 1
              ? "#EFB701"
              : value === 2
              ? "#F71B03"
              : "#F7F7F7",
          fontSize: "14px",

          color: (x==row & y==col) ? "#444" : value === 0 ? "#18A367" : value === 1 ? "#EFB701" : value === 2 ? "#F71B03" : "#18A367"
        })}

     cellRender={(value,x,y) => 'X' }

  />

  :
<div>

   <HeatMap
    xLabels={company_data[1].map((content, i) => <div style={{'fontWeight':'bold','fontSize':'9px'}}> {content} </div>)}
    yLabels={yLabels1}
    data={company_data[0]}

    height={55}
    yLabelWidth={0}
    xLabelWidth={73}
    xLabelsLocation={'top'}
    onClick={(col,row)=> updateState(col,row)}

    cellStyle={(background, value, min, max, data, x, y) => ({
          background:
            value === 0
              ? "#18A367"
              : value === 1
              ? "#EFB701"
              : value === 2
              ? "#F71B03"
              : "#F7F7F7",
          fontSize: "14px",

          color: (x==row & y==col) ? "#444" : value === 0 ? "#18A367" : value === 1 ? "#EFB701" : value === 2 ? "#F71B03" : "#18A367"
        })}

     cellRender={(value,x,y) => 'X' }

  />
  </div>
}
{/*  (x=={row} & y=={col}) ? 'X' : '' */}
 </div>
}
</>
    );
};

const mapStateToProps = state => ({
    company_data: state.db_data.company_data,
    selection_list: state.db_data.selection_list,
    tab_view: state.db_data.tab_view
});



export default connect(mapStateToProps,{change_sentiment_box})(CompanyMap);
