import React, { useState,useEffect } from 'react';
import CompanyMap from "../components/CompanyMap";
import CompanyDetail from "../components/CompanyDetail";
import CompanyDropdown from "../components/CompanyDropdown";
import SentenceModal from "../components/SentenceModal";

import Info from "../components/Info";

import SentimentBox from "../components/SentimentBox";

import { connect } from 'react-redux';

import { get_dropdown_list } from '../actions/db_data';
import { get_company_data } from '../actions/db_data';
import { set_map_null } from '../actions/db_data';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import {  useParams } from 'react-router-dom';

import myData from './data.json';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Company = ({get_dropdown_list,get_company_data,set_map_null,company_data}) => {

const classes = useStyles();


const { id } = useParams();


{/* company_name = comp_fincode[id] */}




useEffect(() => {
    var company_name= myData[id];
    set_map_null();
    get_company_data(company_name);


  }, []);



    return (
    <>
  <Typography align="center" variant="h5"> Transcript Analyser</Typography>
  <p></p>

    <div className={classes.root}>
      <Grid container spacing={3}>


{ company_data!=null && ( (company_data[4]=='FIN') || (company_data[4]=='BNK')  ) ?
   <Grid item xs={3} sm={1}>
          <Paper className={classes.paper} >
          <div style={{paddingTop: "35px"}}>
          <p>Revenue </p>
           <p style= {{marginTop: "30px" }}>Cost </p>
           <p style= {{marginTop: "30px" }}>Margin </p>
           <p style= {{marginTop: "30px" }}>Asset Quality </p>
           <p style= {{marginTop: "30px" }}>Growth </p>
           <p style= {{marginTop: "30px" }}>Debt </p>
           <p style= {{marginTop: "30px" }}>Key Metrics </p>


          </div>

          </Paper>
        </Grid>

 :
   <Grid item xs={3} sm={1}>
          <Paper className={classes.paper} >
          <div style={{paddingTop: "35px"}}>

           <p>Revenue </p>
           <p style= {{marginTop: "30px" }}>Cost </p>
           <p style= {{marginTop: "30px" }}>Margin </p>
           <p style= {{marginTop: "30px" }}>Cash Flow </p>
           <p style= {{marginTop: "30px" }}>Growth </p>
           <p style= {{marginTop: "30px" }}>Debt </p>
           <p style= {{marginTop: "30px" }}>Others </p>
          </div>

          </Paper>
        </Grid>
 }



     <Grid item xs={9} sm={7}>
          <Paper className={classes.paper} ><CompanyMap /></Paper>
   <p> </p>
   <Hidden smDown>
          <Info />
       </Hidden>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}><SentimentBox /></Paper>
        </Grid>

      </Grid>

    </div>


     <CompanyDetail />






     </>
    );
};

const mapStateToProps = state => ({
    company_data: state.db_data.company_data
});

export default connect(mapStateToProps,{get_dropdown_list,get_company_data,set_map_null})(Company);



