import React, { useState,useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { connect } from 'react-redux';


const Wait = ({selection_list,tab_view,flag_empty}) => {



    return (
    <>
    {
    ((selection_list.length==0)  )  ?
    <>
    <p>No Data Selected</p>
    <p> Add Any Sector or Company from top </p>
    </>

    :
    <>
    {

    flag_empty ?

    <>
    <p>No Companies Match the Filter Criteria</p>
    <p>  </p>
    </>

    :

    <CircularProgress />
  }

  </>

    }





    </>

    )


};



const mapStateToProps = state => ({
    selection_list: state.db_data.selection_list,
    tab_view: state.db_data.tab_view,
    flag_empty: state.db_data.flag_empty,

});



export default connect(mapStateToProps,null)(Wait);