import React, { useState } from 'react';
import { connect } from 'react-redux';




const CompanyDetail = ({company_data}) => {


    return (
     <p></p>
    );
};


const mapStateToProps = state => ({
    company_data: state.db_data.company_data
});



export default connect(mapStateToProps,null)(CompanyDetail);