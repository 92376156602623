import React, { useState, useEffect } from 'react';



import Sector from './containers/Sector';

import Home1 from './containers/Home';

import { connect } from 'react-redux';


const Home = ({tab_view}) => {


  return (
    <>
    {
    tab_view=='compare'
     ?  <Sector />

     : <Home1 />
    }

    </>
  );

}

const mapStateToProps = state => ({
    tab_view: state.db_data.tab_view


});




export default connect(mapStateToProps,null)(Home);








