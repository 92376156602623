
import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { update_tab_value } from '../actions/db_data';

import { set_map1_null } from '../actions/db_data';

import { set_map_null } from '../actions/db_data';

import { get_latest_company_data } from '../actions/db_data';

import { intialise_list_value } from '../actions/db_data';

import { update_quarter_value } from '../actions/db_data';

import { filter_dropdown_value } from '../actions/db_data';



import { connect } from 'react-redux';

const ToggleField = ({tab_view,update_tab_value,set_map1_null,get_latest_company_data,set_map_null,intialise_list_value,update_quarter_value,filter_dropdown_value,comp_initial_list}) => {
  const [alignment, setAlignment] = React.useState(tab_view);

  const handleChange = (event, newAlignment) => {


if(newAlignment=='specific'){
  set_map1_null();

  if(comp_initial_list == null){
  intialise_list_value('general','default');
  }
  else{
  intialise_list_value('general', comp_initial_list );
  }

  }

  else{
  set_map_null();

  if(comp_initial_list == null){
  intialise_list_value('general','default');
  }
  else{
  intialise_list_value('general', comp_initial_list );
  }


  update_quarter_value('Q1 2025');
  filter_dropdown_value('All');


  }


    setAlignment(newAlignment);

    update_tab_value(newAlignment);
  };

  return (
  <div  style={{  'float':'right','marginRight':'15px'}} >
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
    >
      <ToggleButton value="specific">Specific Company</ToggleButton>
      <ToggleButton value="compare">Compare Companies</ToggleButton>

    </ToggleButtonGroup>
    </div>
  );
}

const mapStateToProps = state => ({
    tab_view: state.db_data.tab_view


});




export default connect(mapStateToProps,{update_tab_value,set_map1_null,get_latest_company_data,set_map_null,intialise_list_value,update_quarter_value,filter_dropdown_value})(ToggleField);
