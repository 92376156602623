import React, { useState,useEffect } from 'react';
import CompanyMap from "../components/CompanyMap";
import CompanyDetail from "../components/CompanyDetail";
import CompanyDropdown from "../components/CompanyDropdown";
import SentenceModal from "../components/SentenceModal";

import Info from "../components/Info";

import SentimentBox from "../components/SentimentBox";

import ToggleField from "../components/ToggleField";

import { connect } from 'react-redux';

import { get_dropdown_list } from '../actions/db_data';
import { get_company_data } from '../actions/db_data';

import { get_latest_company_data } from '../actions/db_data';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';


import Button from '@material-ui/core/Button';
import CloseIcon from '@mui/icons-material/Close';

import QuarterDropdown from "../components/QuarterDropdown";
import SectorDropdown from "../components/SectorDropdown";
import Selection from "../components/Selection";

import CheckType from "../components/CheckType";

import { delete_selection_value } from '../actions/db_data';

import { get_sector_data } from '../actions/db_data';

import { set_map_null } from '../actions/db_data';

import { update_check_value } from '../actions/db_data';

import { update_heading_value } from '../actions/db_data';

import { flag_empty_filter } from '../actions/db_data';

import Filter from "../components/Filter1";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
}));

const Sector = ({get_dropdown_list,get_company_data, company_data,get_latest_company_data,delete_selection_value,get_sector_data,selection_list,quarter_value,set_map_null,update_check_value, update_heading_value,company_sector,filter_value,small_cap,mid_cap,large_cap,flag_empty_filter}) => {

const classes = useStyles();






useEffect(() => {

var filteredArray=[];

if(filter_value == 'All'){

filteredArray = selection_list;


}

if(filter_value == 'Small Cap'){

filteredArray = selection_list.filter(value => small_cap.includes(value));


}

else if(filter_value == 'Mid Cap'){

filteredArray = selection_list.filter(value => mid_cap.includes(value));


}

else if (filter_value == 'Large Cap'){

filteredArray = selection_list.filter(value => large_cap.includes(value));


}

else{

filteredArray = selection_list;



}


var counts={};


 selection_list.map(

           (content, i) =>

            counts[company_sector[content]] = 1 + (counts[company_sector[content]] || 0)


           )

update_heading_value(counts);



set_map_null();

get_sector_data(filteredArray,quarter_value);

if(selection_list.length==0  ){

update_check_value(null);
set_map_null();



}

if(filteredArray.length==0 & selection_list.length!=0   ){

set_map_null();

flag_empty_filter(true)

}

else{

flag_empty_filter(false)

}




  }, [selection_list,quarter_value,filter_value]);




    return (
    <>
  <Typography align="center" variant="h5">Transcript Analyser</Typography>
  <p></p>
  <Grid container spacing={3}>
  <Hidden smUp >
<ToggleField />
</Hidden>
   <Grid item xs={12} sm={8}>
   <div style={{'display':'flex','justifyContent':'flex-start'}}>

  <SectorDropdown />

  <Typography style={{'marginTop':'20px','marginLeft':'15px','marginRight':'30px','cursor':'pointer'}} color="primary" variant="subtitle1" display="block" gutterBottom onClick={delete_selection_value}>Clear all</Typography>

<Hidden xsDown >
<div style={{'marginLeft': 'auto'}}>


  <QuarterDropdown  />
</div>

</Hidden>


<Hidden xsDown >
<div style={{'marginLeft': '15px'}} >


  <Filter />
</div>

</Hidden>
  </div>

<Hidden smUp >
<Grid item xs={6} sm={8}>
  < Filter />
 </Grid>
</Hidden>


  <Paper variant="outlined"   style={{maxHeight: 120, overflow: 'auto',minHeight: 120, borderRadius:'20px'}}> <Selection /> </Paper>
  </Grid>


   <Grid item xs={12} sm={4}>

<Hidden xsDown >

    <ToggleField />
 </Hidden>



    </Grid>



    </Grid>
    <div className={classes.root}>
      <Grid container spacing={3}>




{ company_data!=null && ( (company_data[4]=='FIN') || (company_data[4]=='BNK')  ) ?
   <Grid item xs={3} sm={1}>
          <Paper className={classes.paper} >
          <div style={{paddingTop: "25px"}}>
          <p>Revenue </p>
           <p style= {{marginTop: "30px" }}>Cost </p>
           <p style= {{marginTop: "30px" }}>Margin </p>
           <p style= {{marginTop: "30px" }}>Asset Quality </p>
           <p style= {{marginTop: "30px" }}>Growth </p>
           <p style= {{marginTop: "30px" }}>Debt </p>
           <p style= {{marginTop: "30px" }}>Key Metrics </p>


          </div>

          </Paper>
        </Grid>

 :
   <Grid item xs={3} sm={1}>
          <Paper className={classes.paper} >
          <div style={{paddingTop: "25px"}}>

           <p>Revenue </p>
           <p style= {{marginTop: "30px" }}>Cost </p>
           <p style= {{marginTop: "30px" }}>Margin </p>
           <p style= {{marginTop: "30px" }}>Cash Flow </p>
           <p style= {{marginTop: "30px" }}>Growth </p>
           <p style= {{marginTop: "30px" }}>Debt </p>
           <p style= {{marginTop: "30px" }}>Others </p>
          </div>

          </Paper>
        </Grid>
 }





     <Grid item xs={9} sm={7}>

          <Paper className={classes.paper} ><CompanyMap /></Paper>

   <Hidden smDown>
          <Info />
       </Hidden>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}><SentimentBox /></Paper>
        </Grid>

      </Grid>

    </div>


     <CompanyDetail />







     </>


    )



    ;


};


const mapStateToProps = state => ({
    company_data: state.db_data.company_data,
    selection_list: state.db_data.selection_list,
    quarter_value: state.db_data.quarter_value,
    company_sector:  state.db_data.company_sector,
    small_cap: state.db_data.small_cap,
    mid_cap: state.db_data.mid_cap,
    large_cap: state.db_data.large_cap,
    filter_value: state.db_data.filter_value


});


export default connect(mapStateToProps,{get_dropdown_list,get_company_data,get_latest_company_data,delete_selection_value,get_sector_data,set_map_null,update_check_value,update_heading_value,flag_empty_filter})(Sector);
