import React, { useState, useEffect  } from 'react';
import { connect } from 'react-redux';
import { makeStyles,createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar  from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { get_detail_component } from '../actions/db_data';
import { set_component_null } from '../actions/db_data';
import { get_detail_component_adjacent } from '../actions/db_data';



import { lazy, Suspense } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

//import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useTheme } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from '@material-ui/core/Paper';

import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '75ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

{/*
const useStyles1 = makeStyles({
  dialog: {
    position: 'relative',
    top: -130
  }
});
*/}


const useStyles1 = makeStyles((theme: Theme) => createStyles({
  dialog: {
    position: 'relative',
    top: -130,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 0
    },
  },
}));

const useStyles2 = makeStyles((theme: Theme) => createStyles({
  root: {
    borderRadius: 0,
    borderColor: "#FFFFFF",

  },
}));

const useStyles9 = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

{/*
const styles = () => ({
  root1: { borderRadius: 0, borderColor: "#FFFFFF", padding: 0 }
});
*/}


const SentimentBox = ({sentence_data,get_detail_component,detail_component,set_component_null,get_detail_component_adjacent,sentence_heading,company_data}) => {


const matches = useMediaQuery('(min-width:600px)');





const theme = useTheme();
  //const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


const classes1 = useStyles1();

const classes2 = useStyles2();

const classes9 = useStyles9();

let closeImg = {cursor:'pointer', float:'right', marginTop: '5px', width: '20px'};


const classes = useStyles();

const [open9, setOpen9] = React.useState(false);

  const [scroll9, setScroll9] = React.useState('paper');


   const handleClose9 = () => {
    setOpen9(false);

    setTranscriptid(null);
    setComponentorder(null);
    setPersonname(null);
    setSpeakertype(null);
    setComponenttype(null);

    setPdflink(null);

    }

 const fullScreen = useMediaQuery(theme.breakpoints.down('md'));



const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const [pdflink, setPdflink] = React.useState(null);

  const [transcriptid, setTranscriptid] = React.useState(null);

  const [componentorder, setComponentorder] = React.useState(null);

  const [personname, setPersonname] = React.useState(null);

  const [speakertype, setSpeakertype] = React.useState(null);

  const [componenttype, setComponenttype] = React.useState(null);

  const handleClickOpen = (scrollType,transcriptId,componentOrder,transcriptPersonName,speakerTypeName,transcriptComponentTypeName) => () => {

  if(transcriptPersonName != 'pdf'){
    setOpen(true);
    setScroll(scrollType);

    setTranscriptid(transcriptId);
    setComponentorder(componentOrder);
    setPersonname(transcriptPersonName);
    setSpeakertype(speakerTypeName);
    setComponenttype(transcriptComponentTypeName);

    get_detail_component_adjacent(transcriptId,componentOrder);

    }

  else{
    setOpen9(true);
    setScroll9(scrollType);

    setTranscriptid(transcriptId);
    setComponentorder(componentOrder);
    setPersonname(transcriptPersonName);
    setSpeakertype(speakerTypeName);
    setComponenttype(transcriptComponentTypeName);

    if(matches ==true){

    setPdflink(transcriptComponentTypeName+ '#zoom=74,0,100&page='+ componentOrder );
    }

    else{
    setPdflink(transcriptComponentTypeName+ '#page='+ componentOrder );
    }


  }



  };

  const handleClose = () => {
    setOpen(false);



    setTranscriptid(null);
    setComponentorder(null);
    setPersonname(null);
    setSpeakertype(null);
    setComponenttype(null);

    set_component_null();

  };

    const navigateComponent = (type,tid,co) => {




    setPersonname(null);
    setSpeakertype(null);
    setComponenttype(null);

    set_component_null();

    if(type=="next"){
setComponentorder(componentorder+1);
get_detail_component_adjacent(tid,co+1);
}

else {

setComponentorder(componentorder-1);
get_detail_component_adjacent(tid,co-1);
}





  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);





    return (
    <>



{ ( sentence_data == null) ?
  <p>Null</p>
 :
<>
{
(company_data != null) ?
<span style={{fontWeight:"bold"}}> {sentence_heading==null ? company_data[1][0]  : sentence_heading[0]} {sentence_heading==null ? company_data[2][0]  : company_data[2][sentence_heading[1]]  } </span>
:
<span style={{fontWeight:"bold"}}>hh </span>

}


<Paper className={classes2.root}  style={{maxHeight: 600, overflow: 'auto'}}>

<List className={classes.root}>

      <ListItem style={{'cursor':'pointer'}} alignItems="flex-start" onClick={handleClickOpen('paper',sentence_data[0]['transcriptId'],sentence_data[0]['componentOrder'],sentence_data[0]['transcriptPersonName'],sentence_data[0]['speakerTypeName'],sentence_data[0]['transcriptComponentTypeName'])}>
        <ListItemAvatar>
           <Avatar alt="Cindy Baker" src={sentence_data[0]['sentiment']=="positive" ? "/static/img/positive7.png" : sentence_data[0]['sentiment']=="negative" ?  "/static/img/negative7.png" : "/static/img/neutral.png"  } />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="h7" style={{ color: sentence_data[0]['sentiment']=="positive" ?  '#18A367' : sentence_data[0]['sentiment']=="negative" ? '#F71B03' : '#EFB701' }}>{sentence_data[0]['sentence']}</Typography>}

        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem style={{'cursor':'pointer'}} alignItems="flex-start" onClick={handleClickOpen('paper',sentence_data[1]['transcriptId'],sentence_data[1]['componentOrder'],sentence_data[1]['transcriptPersonName'],sentence_data[1]['speakerTypeName'],sentence_data[1]['transcriptComponentTypeName'])}>
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src={sentence_data[1]['sentiment']=="positive" ? "/static/img/positive7.png" : sentence_data[1]['sentiment']=="negative" ?  "/static/img/negative7.png" : "/static/img/neutral.png"  } />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="h7" style={{ color: sentence_data[1]['sentiment']=="positive" ?  '#18A367' : sentence_data[1]['sentiment']=="negative" ? '#F71B03' : '#EFB701' }}>{sentence_data[1]['sentence']}</Typography>}

        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem style={{'cursor':'pointer'}} alignItems="flex-start" onClick={handleClickOpen('paper',sentence_data[2]['transcriptId'],sentence_data[2]['componentOrder'],sentence_data[2]['transcriptPersonName'],sentence_data[2]['speakerTypeName'],sentence_data[2]['transcriptComponentTypeName'])}>
        <ListItemAvatar>
           <Avatar alt="Cindy Baker" src={sentence_data[2]['sentiment']=="positive" ? "/static/img/positive7.png" : sentence_data[2]['sentiment']=="negative" ?  "/static/img/negative7.png" : "/static/img/neutral.png"  } />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="h7" style={{ color: sentence_data[2]['sentiment']=="positive" ?  '#18A367' : sentence_data[2]['sentiment']=="negative" ? '#F71B03' : '#EFB701' }}>{sentence_data[2]['sentence']}</Typography>}

        />
      </ListItem>

    <Divider variant="inset" component="li" />
      <ListItem style={{'cursor':'pointer'}} alignItems="flex-start" onClick={handleClickOpen('paper',sentence_data[3]['transcriptId'],sentence_data[3]['componentOrder'],sentence_data[3]['transcriptPersonName'],sentence_data[3]['speakerTypeName'],sentence_data[3]['transcriptComponentTypeName'])}>
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src={sentence_data[3]['sentiment']=="positive" ? "/static/img/positive7.png" : sentence_data[3]['sentiment']=="negative" ?  "/static/img/negative7.png" : "/static/img/neutral.png"  } />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="h7" style={{ color: sentence_data[3]['sentiment']=="positive" ?  '#18A367' : sentence_data[3]['sentiment']=="negative" ? '#F71B03' : '#EFB701' }}>{sentence_data[3]['sentence']}</Typography>}

        />
      </ListItem>

     <Divider variant="inset" component="li" />
      <ListItem style={{'cursor':'pointer'}} alignItems="flex-start" onClick={handleClickOpen('paper',sentence_data[4]['transcriptId'],sentence_data[4]['componentOrder'],sentence_data[4]['transcriptPersonName'],sentence_data[4]['speakerTypeName'],sentence_data[4]['transcriptComponentTypeName'])}>
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src={sentence_data[4]['sentiment']=="positive" ? "/static/img/positive7.png" : sentence_data[4]['sentiment']=="negative" ?  "/static/img/negative7.png" : "/static/img/neutral.png"  } />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="h7" style={{ color: sentence_data[4]['sentiment']=="positive" ?  '#18A367' : sentence_data[4]['sentiment']=="negative" ? '#F71B03' : '#EFB701' }}>{sentence_data[4]['sentence']}</Typography>}

        />
      </ListItem>

    </List>
</Paper>


  <Dialog


        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >


        <DialogTitle id="scroll-dialog-title" style={{display:"flex"}}>

        {detail_component==null ? componenttype : detail_component[0][1]}
        &nbsp;  &nbsp;
<span style={{textAlign: "right"}}>
        <IconButton onClick={handleClose}  >
            <CloseIcon />
        </IconButton>
        </span>


        </DialogTitle>
<div style={{flexDirection: 'row'}}>
         <Button onClick={() => navigateComponent("previous",transcriptid,componentorder)} color="primary">
            Previous
          </Button>
          <Button onClick={() => navigateComponent("next",transcriptid,componentorder)} color="primary">
            Next
          </Button>
          <span style={{'color':'grey','fontSize':'10px'}}>Click to see Adjacent discussion related to this particular {detail_component==null ? componenttype : detail_component[0][1]}</span>
       </div>

        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >

         <p> <span style={{fontWeight:"bold"}}>Speaker Name:</span> <span> {detail_component==null ? personname : detail_component[0][3]}</span>
         &nbsp; &nbsp;
          <span style={{fontWeight:"bold"}}>Speaker Type:</span>  <span> {detail_component==null ? speakertype : detail_component[0][2]}</span>
          </p>
{ ( detail_component == null) ? <div style={{
        'margin': '50px 0',
       'margin-bottom': '20px',
      'padding': '30px 50px',
      'text-align': 'center',
    'border-radius': '4px',}}> {personname=='not found' ? <></> : <CircularProgress /> }</div>

:
          <p>{detail_component[0][0]}</p>
 }
          </DialogContentText>
        </DialogContent>
        <DialogActions>




        </DialogActions>
      </Dialog>



       <Dialog

       fullScreen={fullScreen}

       fullWidth={true}
       maxWidth={'md'}



        open={open9}
        onClose={handleClose9}
        scroll={scroll9}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >


        <DialogTitle id="scroll-dialog-title" style={{display:"flex"}}>



<span style={{textAlign: "right"}}>
        <IconButton onClick={handleClose9}  >
            <CloseIcon />
        </IconButton>
        </span>


        </DialogTitle>


        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >

          <iframe id={'my_iframe'}  src={pdflink}  width='100%' height='100%' style={{'border':'1px solid black', 'width':'100%', 'height':'850px'}}  allowTransparency="true"  > </iframe>





          </DialogContentText>
        </DialogContent>
        <DialogActions>




        </DialogActions>
      </Dialog>




</>

}



</>

    );
};
 {/* onInputChange */}

const mapStateToProps = state => ({
    sentence_data: state.db_data.sentence_data,
    detail_component: state.db_data.detail_component,
    sentence_heading: state.db_data.sentence_heading,
    company_data: state.db_data.company_data
});

export default connect(mapStateToProps,{get_detail_component,set_component_null,get_detail_component_adjacent})(SentimentBox);