import React, { useState} from 'react';

import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import CloseIcon from '@mui/icons-material/Close';

import { delete_specific_value } from '../actions/db_data';

import { delete_sector_value } from '../actions/db_data';



const Selection = ({selection_list,delete_specific_value,sector_heading,delete_sector_value}) => {



    return (

    <div>

<div>
 {
 Object.entries(sector_heading).map(([key,value]) => {

 return(

 <Button variant="contained" color="primary"  style={{'marginLeft':'10px','marginTop':'5px','marginBottom':'5px'}}  endIcon={<CloseIcon onClick= {()=>delete_sector_value(key)} />} > {key} ({value}) </ Button>

)
 })

 }

</div>
{
    selection_list.map(

           (content, i) =>{



           return(

     <Button  color="primary" style={{'marginLeft':'10px','marginTop':'10px'}} variant="outlined" endIcon={<CloseIcon onClick= {()=>delete_specific_value(content)} />} > {content.slice(0,content.length - 5)} </ Button>

)
}
        )

}
     </div>
    );
};

const mapStateToProps = state => ({
    selection_list: state.db_data.selection_list,
    sector_heading : state.db_data.sector_heading,


});


export default connect(mapStateToProps,{delete_specific_value,delete_sector_value})(Selection);

