import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField  from '@material-ui/core/TextField';

import { get_company_data } from '../actions/db_data';

import { set_map_null } from '../actions/db_data';

import { connect } from 'react-redux';

const CompanyDropdown = ({get_company_data,dropdown_list,set_map_null,company_data,first_time}) => {

const [default7, setDefault7] = useState('ok');




 const fetch_data = async (company_name) => {



if(company_data != null){


if(company_name!=null & company_name!=company_data[5])  {
get_company_data(company_name);
setDefault7('no')
set_map_null();
}

}

else{
get_company_data(company_name);
}



    };



const default1= 'k';

const company_list=dropdown_list;





return (

<>

{
first_time==null   ?

null

:
     <Autocomplete
  id="combo-box-demo"
  options={company_list}
  defaultValue= {company_data==null ? 'null' : company_data[5]}

  getOptionLabel={(option) => option}
  style={{ width: 300 }}
  renderInput={(params) => <TextField {...params} label="Enter Company Name" variant="outlined" />}
  onChange= {(event,value)=> fetch_data(value) }

/>

}

</>
    );



};


 {/* onInputChange */}

const mapStateToProps = state => ({
    dropdown_list: state.db_data.dropdown_list,
    company_data: state.db_data.company_data,
    first_time: state.db_data.first_time


});

export default connect(mapStateToProps,{ get_company_data,set_map_null})(CompanyDropdown);