import React, { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));



const Info = () => {

const classes = useStyles();

    return (
     <List className={classes.root}>

      <ListItem alignItems="flex-start">

      <ListItemIcon>
            <InfoOutlinedIcon />
          </ListItemIcon>

        <ListItemText
          primary={<Typography variant="subtitle2">- Select any cell to see the top 5 relevant discussion on that topic in the right hand side panel
            <br/>- Select any sentence in the right hand side panel, To know the entire context of that discussion.
           </Typography>}

        />
      </ListItem>
      <Divider variant="inset" component="li" />

      </List>

    );
};






export default Info;