import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { filter_selection_value } from '../actions/db_data';

import { connect } from 'react-redux';

import { filter_dropdown_value } from '../actions/db_data';




const Filter1 = ({selection_list,small_cap,mid_cap,large_cap,filter_selection_value,filter_value,filter_dropdown_value}) => {

const top100Films = [
  { label: 'All', year: 'All' },
  { label: 'Small Cap', year: 'Small Cap' },
  { label: 'Mid Cap', year: 'Mid Cap' },
  { label: 'Large Cap', year: 'Large Cap' },

];

const [field, setField] = React.useState(null);


 const fetch_data = async (cap_value) => {



if(cap_value.label == 'Small Cap'){

const filteredArray = selection_list.filter(value => small_cap.includes(value));
filter_selection_value(filteredArray);

}

else if(cap_value.label == 'Mid Cap'){

const filteredArray = selection_list.filter(value => mid_cap.includes(value));
filter_selection_value(filteredArray);

}

else if (cap_value.label == 'Large Cap'){

const filteredArray = selection_list.filter(value => large_cap.includes(value));
filter_selection_value(filteredArray);

}




 };

  return (

   <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 180 }}
      renderInput={(params) => <TextField {...params} label="Apply M-Cap Filter" />}
      value={filter_value}
      onChange= {(event,value)=> filter_dropdown_value(value.label) }


    />

  );
}

const mapStateToProps = state => ({
    selection_list: state.db_data.selection_list,
    small_cap: state.db_data.small_cap,
    mid_cap: state.db_data.mid_cap,
    large_cap: state.db_data.large_cap,
    filter_value: state.db_data.filter_value,

});




export default connect(mapStateToProps,{filter_selection_value,filter_dropdown_value})(Filter1);
