import {
    COMPANY_DATA_SUCCESS,
    COMPANY_DATA_FAIL,
    DROPDOWN_DATA_SUCCESS,
    DROPDOWN_DATA_FAIL,
    SENTIMENT_BOX_SUCCESS,
    SENTIMENT_BOX_FAIL,
    DETAIL_COMPONENT_SUCCESS,
    DETAIL_COMPONENT_FAIL,
    SET_COMPONENT_SUCCESS,
    SET_COMPONENT_FAIL,
    ADJACENT_COMPONENT_SUCCESS,
    ADJACENT_COMPONENT_FAIL,
    HEADING_BOX_SUCCESS,
    HEADING_BOX_FAIL,
    SET_MAP_SUCCESS,
    SET_MAP_FAIL,
    FIRST_SUCCESS,
    FIRST_FAIL,

     UPDATE_SELECTION_SUCCESS,
    UPDATE_SELECTION_FAIL,
    DELETE_SELECTION_SUCCESS,
    DELETE_SELECTION_FAIL,
    UPDATE_TAB_SUCCESS,
    UPDATE_TAB_FAIL,

    UPDATE_CHECK_SUCCESS,
    UPDATE_CHECK_FAIL,

    UPDATE_QUARTER_SUCCESS,
    UPDATE_QUARTER_FAIL,

    DELETE_SPECIFIC_SUCCESS,
    DELETE_SPECIFIC_FAIL,

    SET_MAP1_SUCCESS,
    SET_MAP1_FAIL,

    INTIALISE_LIST_SUCCESS,
    INTIALISE_LIST_FAIL,

    FILTER_SELECTION_SUCCESS,
    FILTER_SELECTION_FAIL,

    UPDATE_HEADING_SUCCESS,
    UPDATE_HEADING_FAIL,

    DELETE_SECTOR_SUCCESS,
    DELETE_SECTOR_FAIL,

    FILTER_DROPDOWN_SUCCESS,
    FILTER_DROPDOWN_FAIL,

    FLAG_EMPTY_SUCCESS,
    FLAG_EMPTY_FAIL,

} from '../actions/types';



const initialState = {
    company_data: null,
    dropdown_list:["null"],
    sentence_data: null,
    detail_component: null,
    sentence_heading:null,
    first_time:null,
    sector_list:[{"name":"","type":"b"}],
    bank_list:[{"name":"","type":"b"}],
    selection_list: [ 'Coforge Ltd.',
 'HCL Technologies Ltd.',  'Infosys Ltd.',
  'Larsen & Toubro Infotech Ltd.',  'Mindtree Ltd.', 'Mphasis Ltd.','NIIT Ltd.', 'Persistent Systems Ltd.',
 'Sonata Software Ltd.','Tata Consultancy Services Ltd.', 'Tata Elxsi Ltd.', 'Tech Mahindra Ltd.',
  'Wipro Ltd.', 'Zensar Technologies Ltd.'],
    tab_view: 'specific',
    check_value: true,
    quarter_value: 'Q4 2023',
    small_cap: [],
    mid_cap: [],
    large_cap:[],
    sector_heading : {},
    company_sector: {'Aurobindo Pharma Ltd.': 'Healthcare',
 'Biocon Ltd.': 'Healthcare',
 'Cadila Healthcare Ltd.': 'Healthcare',
 'Cipla Ltd.': 'Healthcare',
 'Dr. Lal Pathlabs Ltd.': 'Healthcare',
 'Dr. Reddys Laboratories Ltd.': 'Healthcare',
 'Glenmark Pharmaceuticals Ltd.': 'Healthcare',
 'Lupin Ltd.': 'Healthcare',
 'Sun Pharmaceutical Industries Ltd.': 'Healthcare',
 'Gland Pharma Ltd.': 'Healthcare',
 'Torrent Pharmaceuticals Ltd.': 'Healthcare',
 'Ipca Laboratories Ltd.': 'Healthcare',
 'Laurus Labs Ltd.': 'Healthcare',
 'Alkem Laboratories Ltd.': 'Healthcare'},

 filter_value:'All',
 flag_empty:false,

};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case COMPANY_DATA_SUCCESS:
            return {
                ...state,
                company_data: payload

            }

        case COMPANY_DATA_FAIL:
            return {
                ...state

            }


        case DROPDOWN_DATA_SUCCESS:
            return {
                ...state,
                dropdown_list: payload[0],
                sector_list: payload[1],
                bank_list: payload[2],
                company_sector: payload[3],


                small_cap: payload[2][0],
                mid_cap: payload[2][1],
                large_cap: payload[2][2],

            }

        case DROPDOWN_DATA_FAIL:
            return {
                ...state

            }


        case SENTIMENT_BOX_SUCCESS:
            return {
                ...state,
                sentence_data: payload

            }

        case SENTIMENT_BOX_FAIL:
            return {
                ...state

            }

         case DETAIL_COMPONENT_SUCCESS:
            return {
                ...state,
                detail_component: payload

            }

        case DETAIL_COMPONENT_FAIL:
            return {
                ...state

            }


          case SET_COMPONENT_SUCCESS:
            return {
                ...state,
                detail_component: null

            }

        case SET_COMPONENT_FAIL:
            return {
                ...state

            }


       case SET_MAP_SUCCESS:
            return {
                ...state,
                company_data: null,
                sentence_data: null

            }

        case SET_MAP_FAIL:
            return {
                ...state,
                company_data: null,
                sentence_data: null

            }


        case ADJACENT_COMPONENT_SUCCESS:
            return {
                ...state,
                detail_component: payload

            }

        case ADJACENT_COMPONENT_FAIL:
            return {
                ...state

            }



        case HEADING_BOX_SUCCESS:
            return {
                ...state,
                sentence_heading: payload

            }

        case HEADING_BOX_FAIL:
            return {
                ...state

            }

        case FIRST_SUCCESS:
            return {
                ...state,
                first_time: 'yes'

            }

        case FIRST_FAIL:
            return {
                ...state

            }


        case UPDATE_SELECTION_SUCCESS:
            return {
                ...state,
                selection_list: [...new Set(state.selection_list.concat(payload))]


            }

        case UPDATE_SELECTION_FAIL:
            return {
                ...state

            }



        case DELETE_SELECTION_SUCCESS:
            return {
                ...state,
                selection_list: []


            }

        case DELETE_SELECTION_FAIL:
            return {
                ...state

            }



        case UPDATE_TAB_SUCCESS:
            return {
                ...state,
                tab_view: payload


            }

        case UPDATE_TAB_FAIL:
            return {
                ...state

            }


        case UPDATE_CHECK_SUCCESS:
            return {
                ...state,
                check_value: payload


            }

        case UPDATE_CHECK_FAIL:
            return {
                ...state

            }



       case UPDATE_QUARTER_SUCCESS:
            return {
                ...state,
                quarter_value: payload


            }

        case UPDATE_QUARTER_FAIL:
            return {
                ...state

            }


       case DELETE_SPECIFIC_SUCCESS:
            return {
                ...state,
                selection_list: state.selection_list.filter(element => element !== payload)


            }

        case DELETE_SPECIFIC_FAIL:
            return {
                ...state

            }


       case SET_MAP1_SUCCESS:
            return {
                ...state,
                company_data: null,
                sentence_data: null,
                first_time:null

            }

        case SET_MAP1_FAIL:
            return {
                ...state,
                company_data: null,
                sentence_data: null

            }



         case INTIALISE_LIST_SUCCESS:
            return {
                ...state,
                selection_list: payload[0],
                check_value: payload[1]


            }

        case INTIALISE_LIST_FAIL:
            return {
                ...state


            }



       case FILTER_SELECTION_SUCCESS:
            return {
                ...state,
                selection_list: payload


            }

        case FILTER_SELECTION_FAIL:
            return {
                ...state


            }


       case UPDATE_HEADING_SUCCESS:
            return {
                ...state,
                sector_heading: payload


            }

        case UPDATE_HEADING_FAIL:
            return {
                ...state


            }


        case DELETE_SECTOR_SUCCESS:
            return {
                ...state,
                selection_list: state.selection_list.filter(element => state.company_sector[element] !== payload)


            }

        case DELETE_SECTOR_FAIL:
            return {
                ...state

            }


        case FILTER_DROPDOWN_SUCCESS:
            return {
                ...state,
                filter_value: payload


            }

        case FILTER_DROPDOWN_FAIL:
            return {
                ...state

            }


        case FLAG_EMPTY_SUCCESS:
            return {
                ...state,
                flag_empty: payload


            }

        case FLAG_EMPTY_FAIL:
            return {
                ...state

            }




        default:
            return state


        }


    };


