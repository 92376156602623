export const COMPANY_DATA_SUCCESS = 'COMPANY_DATA_SUCCESS';
export const COMPANY_DATA_FAIL = 'COMPANY_DATA_FAIL';

export const DROPDOWN_DATA_SUCCESS = 'DROPDOWN_DATA_SUCCESS';
export const DROPDOWN_DATA_FAIL = 'DROPDOWN_DATA_FAIL';

export const SENTIMENT_BOX_SUCCESS = 'SENTIMENT_BOX_SUCCESS';
export const SENTIMENT_BOX_FAIL = 'SENTIMENT_BOX_FAIL';


export const DETAIL_COMPONENT_SUCCESS = 'DETAIL_COMPONENT_SUCCESS';
export const DETAIL_COMPONENT_FAIL = 'DETAIL_COMPONENT_FAIL';


export const SET_COMPONENT_SUCCESS = 'SET_COMPONENT_SUCCESS';
export const SET_COMPONENT_FAIL = 'SET_COMPONENT_FAIL';

export const ADJACENT_COMPONENT_SUCCESS = 'ADJACENT_COMPONENT_SUCCESS';
export const ADJACENT_COMPONENT_FAIL = 'ADJACENT_COMPONENT_FAIL';

export const HEADING_BOX_SUCCESS = 'HEADING_BOX_SUCCESS';
export const HEADING_BOX_FAIL = 'HEADING_BOX_FAIL';

export const SET_MAP_SUCCESS = 'SET_MAP_SUCCESS';
export const SET_MAP_FAIL = 'SET_MAP_FAIL';

export const FIRST_SUCCESS = 'FIRST_SUCCESS';
export const FIRST_FAIL = 'FIRST_FAIL';


export const UPDATE_SELECTION_SUCCESS = 'UPDATE_SELECTION_SUCCESS';
export const UPDATE_SELECTION_FAIL = 'UPDATE_SELECTION_FAIL';


export const DELETE_SELECTION_SUCCESS = 'DELETE_SELECTION_SUCCESS';
export const DELETE_SELECTION_FAIL = 'DELETE_SELECTION_FAIL';

export const UPDATE_TAB_SUCCESS = 'UPDATE_TAB_SUCCESS';
export const UPDATE_TAB_FAIL = 'UPDATE_TAB_FAIL';

export const UPDATE_CHECK_SUCCESS = 'UPDATE_CHECK_SUCCESS';
export const UPDATE_CHECK_FAIL = 'UPDATE_CHECK_FAIL';

export const UPDATE_QUARTER_SUCCESS = 'UPDATE_QUARTER_SUCCESS';
export const UPDATE_QUARTER_FAIL = 'UPDATE_QUARTER_FAIL';



export const DELETE_SPECIFIC_SUCCESS = 'DELETE_SPECIFIC_SUCCESS';
export const DELETE_SPECIFIC_FAIL = 'DELETE_SPECIFIC_FAIL';


export const SET_MAP1_SUCCESS = 'SET_MAP1_SUCCESS';
export const SET_MAP1_FAIL = 'SET_MAP1_FAIL';

export const INTIALISE_LIST_SUCCESS = 'INTIALISE_LIST_SUCCESS';
export const INTIALISE_LIST_FAIL = 'INTIALISE_LIST_FAIL';

export const FILTER_SELECTION_SUCCESS = 'FILTER_SELECTION_SUCCESS';
export const FILTER_SELECTION_FAIL = 'FILTER_SELECTION_FAIL';

export const UPDATE_HEADING_SUCCESS = 'UPDATE_HEADING_SUCCESS';
export const UPDATE_HEADING_FAIL = 'UPDATE_HEADING_FAIL';

export const DELETE_SECTOR_SUCCESS = 'DELETE_SECTOR_SUCCESS';
export const DELETE_SECTOR_FAIL = 'DELETE_SECTOR_FAIL';

export const FILTER_DROPDOWN_SUCCESS = 'FILTER_DROPDOWN_SUCCESS';
export const FILTER_DROPDOWN_FAIL = 'FILTER_DROPDOWN_FAIL';


export const FLAG_EMPTY_SUCCESS = 'FLAG_EMPTY_SUCCESS';
export const FLAG_EMPTY_FAIL = 'FLAG_EMPTY_FAIL';




