import axios from 'axios';

import {
    COMPANY_DATA_SUCCESS,
    COMPANY_DATA_FAIL,
    DROPDOWN_DATA_SUCCESS,
    DROPDOWN_DATA_FAIL,
    SENTIMENT_BOX_SUCCESS,
    SENTIMENT_BOX_FAIL,
    DETAIL_COMPONENT_SUCCESS,
    DETAIL_COMPONENT_FAIL,
    SET_COMPONENT_SUCCESS,
    SET_COMPONENT_FAIL,
    ADJACENT_COMPONENT_SUCCESS,
    ADJACENT_COMPONENT_FAIL,
    HEADING_BOX_SUCCESS,
    HEADING_BOX_FAIL,
    SET_MAP_SUCCESS,
    SET_MAP_FAIL,
    FIRST_SUCCESS,
    FIRST_FAIL,

    UPDATE_SELECTION_SUCCESS,
    UPDATE_SELECTION_FAIL,

    DELETE_SELECTION_SUCCESS,
    DELETE_SELECTION_FAIL,

    UPDATE_TAB_SUCCESS,
    UPDATE_TAB_FAIL,

    UPDATE_CHECK_SUCCESS,
    UPDATE_CHECK_FAIL,

    UPDATE_QUARTER_SUCCESS,
    UPDATE_QUARTER_FAIL,

    DELETE_SPECIFIC_SUCCESS,
    DELETE_SPECIFIC_FAIL,

    SET_MAP1_SUCCESS,
    SET_MAP1_FAIL,

    INTIALISE_LIST_SUCCESS,
    INTIALISE_LIST_FAIL,

    FILTER_SELECTION_SUCCESS,
    FILTER_SELECTION_FAIL,

    UPDATE_HEADING_SUCCESS,
    UPDATE_HEADING_FAIL,

    DELETE_SECTOR_SUCCESS,
    DELETE_SECTOR_FAIL,

    FILTER_DROPDOWN_SUCCESS,
    FILTER_DROPDOWN_FAIL,

    FLAG_EMPTY_SUCCESS,
    FLAG_EMPTY_FAIL,

    } from './types';

const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});




export const get_company_data= (company_name) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ company_name:company_name});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/company-data`,body,config );



            dispatch({
                type: COMPANY_DATA_SUCCESS,
                payload: res.data['key']
            });

            dispatch({
                type: SENTIMENT_BOX_SUCCESS,
                payload: res.data['key'][3][0][0]
            });

             dispatch({
                type: HEADING_BOX_SUCCESS,
                payload: [res.data['key'][1][0],0]
            });



        } catch (err) {
            dispatch({
                type: COMPANY_DATA_FAIL
            });


        }






};



export const get_latest_company_data= (company_name) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        const body = JSON.stringify({ company_name:company_name});




        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/latest-company-data`,body,config );



            dispatch({
                type: COMPANY_DATA_SUCCESS,
                payload: res.data['key']
            });

            dispatch({
                type: SENTIMENT_BOX_SUCCESS,
                payload: res.data['key'][3][0][0]
            });

             dispatch({
                type: HEADING_BOX_SUCCESS,
                payload: [res.data['key'][1][0],0]
            });

             dispatch({
                type: FIRST_SUCCESS,
                payload: 'ok'
            });

        } catch (err) {
            dispatch({
                type: COMPANY_DATA_FAIL
            });


        }






};



export const get_dropdown_list= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/dropdown-list`);

            const payload_list = [res.data['key'],res.data['key1'],res.data['key2'],res.data['key3']];





            dispatch({
                type: DROPDOWN_DATA_SUCCESS,
                payload: payload_list
            });
        } catch (err) {
            dispatch({
                type: DROPDOWN_DATA_FAIL
            });
        }


};


export const change_sentiment_box= (sent_obj,x_label,y_label) => async dispatch => {
      console.log(x_label);
      console.log(y_label);

      var head=[x_label,y_label];

      try{
            dispatch({
                type: SENTIMENT_BOX_SUCCESS,
                payload: sent_obj
            });



           }

       catch (err) {
            dispatch({
                type: SENTIMENT_BOX_FAIL
            });
        }



      try{
            dispatch({
                type: HEADING_BOX_SUCCESS,
                payload: head
            });



           }

       catch (err) {
            dispatch({
                type: HEADING_BOX_FAIL
            });
        }








};




export const get_detail_component= (transcriptId,componentOrder) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        const body = JSON.stringify({  transcriptId:transcriptId, componentOrder:componentOrder});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/detail-component`,body ,config);

            dispatch({
                type: DETAIL_COMPONENT_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: DETAIL_COMPONENT_FAIL
            });


        }







};




export const set_component_null= () => async dispatch => {

      try{
            dispatch({
                type: SET_COMPONENT_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: SET_COMPONENT_FAIL
            });
        }



};


export const set_map_null= () => async dispatch => {

      try{
            dispatch({
                type: SET_MAP_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: SET_MAP_FAIL
            });
        }



};






export const get_detail_component_adjacent= (transcriptId,componentOrder) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        const body = JSON.stringify({  transcriptId:transcriptId, componentOrder:componentOrder});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/detail-component-adjacent`,body ,config);

            dispatch({
                type: ADJACENT_COMPONENT_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: ADJACENT_COMPONENT_FAIL
            });


        }







};




export const update_selection_value= (value) => async dispatch => {

      if(value.type=='Sector'){

       const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        const body = JSON.stringify({  'sector_name':value.name});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sector-company`,body ,config);

            dispatch({
                type: UPDATE_SELECTION_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: UPDATE_SELECTION_FAIL
            });


        }

        }

      else {

      try {

            dispatch({
                type: UPDATE_SELECTION_SUCCESS,
                payload: value.name
            });



        } catch (err) {
            dispatch({
                type: UPDATE_SELECTION_FAIL
            });


        }



      }




};




export const delete_selection_value= () => async dispatch => {

      try{
            dispatch({
                type: DELETE_SELECTION_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: DELETE_SELECTION_FAIL
            });
        }



};




export const get_sector_data= (company_list,quarter) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };

        console.log(company_list,quarter,'ashish123');


        const body = JSON.stringify({ 'company_list':company_list, 'quarter':quarter});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sector-data`,body,config );

            if(res.data['key'][0] != 'none'){


            dispatch({
                type: COMPANY_DATA_SUCCESS,
                payload: res.data['key']
            });

            dispatch({
                type: SENTIMENT_BOX_SUCCESS,
                payload: res.data['key'][3][0][0]
            });

             dispatch({
                type: HEADING_BOX_SUCCESS,
                payload: [res.data['key'][1][0],0]
            });

            }

           else if (res.data['key'][0] == 'none' & res.data['key'][1] == 'no_result') {

            dispatch({
                type: FLAG_EMPTY_SUCCESS,
                payload: true
            });



           }



        } catch (err) {
            dispatch({
                type: COMPANY_DATA_FAIL
            });


        }






};




export const update_tab_value= (value) => async dispatch => {

      try{
            dispatch({
                type: UPDATE_TAB_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: UPDATE_TAB_FAIL
            });
        }



};




export const update_check_value= (value) => async dispatch => {

      try{
            dispatch({
                type: UPDATE_CHECK_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: UPDATE_CHECK_FAIL
            });
        }



};


export const update_quarter_value= (value) => async dispatch => {

      try{
            dispatch({
                type: UPDATE_QUARTER_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: UPDATE_QUARTER_FAIL
            });
        }



};



export const delete_specific_value= (value) => async dispatch => {

      try{
            dispatch({
                type: DELETE_SPECIFIC_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: DELETE_SPECIFIC_FAIL
            });
        }



};


export const set_map1_null= () => async dispatch => {

      try{
            dispatch({
                type: SET_MAP1_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: SET_MAP1_FAIL
            });
        }



};


export const intialise_list_value= (value,comp_name) => async dispatch => {

     if(value=='general'){

     if(comp_name == 'default'){

     var initial_list= [ 'Coforge Ltd.',
 'HCL Technologies Ltd.',  'Infosys Ltd.',
  'Larsen & Toubro Infotech Ltd.',  'Mindtree Ltd.', 'Mphasis Ltd.','NIIT Ltd.', 'Persistent Systems Ltd.',
 'Sonata Software Ltd.','Tata Consultancy Services Ltd.', 'Tata Elxsi Ltd.', 'Tech Mahindra Ltd.',
  'Wipro Ltd.', 'Zensar Technologies Ltd.']
  }

  else{

  const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


  const body = JSON.stringify({ 'comp_name':comp_name });

  const res = await axios.post(`${process.env.REACT_APP_API_URL}/mobile/sector-initial-list`,body,config );

  var initial_list = res.data['key'] ;



  }

 var check_value= true

     }

     else{

     if(comp_name == 'default'){

     var initial_list = [ 'Axis Bank Ltd.',
 'HDFC Bank Ltd.', 'ICICI Bank Ltd.', 'IDFC First Bank Ltd.', 'Kotak Mahindra Bank Ltd.', 'Punjab National Bank',
 'State Bank Of India', 'Yes Bank Ltd.']
  }


  else{

  const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


  const body = JSON.stringify({ 'comp_name':comp_name });

  const res = await axios.post(`${process.env.REACT_APP_API_URL}/sector-initial-list`,body,config );

  var initial_list = res.data['key'] ;



  }



 var check_value=false


     }

     const payload_list =[initial_list,check_value]

      try{
            dispatch({
                type: INTIALISE_LIST_SUCCESS,
                payload: payload_list
            });

           }

       catch (err) {
            dispatch({
                type: INTIALISE_LIST_FAIL
            });
        }



};



export const filter_selection_value= (value) => async dispatch => {

      try{
            dispatch({
                type: FILTER_SELECTION_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: FILTER_SELECTION_FAIL
            });
        }



};



export const update_heading_value= (value) => async dispatch => {

      try{
            dispatch({
                type: UPDATE_HEADING_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: UPDATE_HEADING_FAIL
            });
        }



};



export const delete_sector_value= (value) => async dispatch => {

      try{
            dispatch({
                type: DELETE_SECTOR_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: DELETE_SECTOR_FAIL
            });
        }



};



export const filter_dropdown_value= (value) => async dispatch => {

      try{
            dispatch({
                type: FILTER_DROPDOWN_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: FILTER_DROPDOWN_FAIL
            });
        }



};


export const flag_empty_filter= (value) => async dispatch => {

      try{
            dispatch({
                type: FLAG_EMPTY_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: FLAG_EMPTY_FAIL
            });
        }



};



