import React  from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Home from './Home';
import Company from './containers/Company';

{/*
 const Home = lazy(() => import("./containers/Home"));
import Home from './containers/Home';

import Footer from "./components/Footer";
import Header from "./components/Header";
*/}




const App = () => (
    <Provider store={store}>
        <Router>
           {/* <Header /> */}
                <Switch>




                    <Route exact path='/' component={Home} />
                    <Route exact path='/simplyir/:id' component={Home} />

                    <Route exact path='/:id' component={Company} />



                </Switch>
        {/* <Footer /> */}
        </Router>
    </Provider>
);

export default App;
